<template>
  <Page
    has-actions
  />
</template>
	
	<script>
	export default { 
	  data() {
		return {
		  rowsPerPage: [50, 100, 200],
		  pagination: { perPage: 50, page: 1 },
		  loading: false,
		  items: [],
		};
	  },
	  created() {
			this.updateTitle(this.$i18n.tc("warehouse", 2));
		  // this.getItems();
	  },  
	  methods: {
		  getItems() {
			  this.loading = true;
			  this.$axios
				  .get(`/warehouses`)
				  .then(res  => {
					  this.items = res.data.list;					
				  })
				  .catch(e => this.$root.clientError(e))
				  .finally(() => (this.loading = false));
		  },
	  }
	};
	</script>